import React, { useRef, useEffect } from 'react';
import Seo from "../../components/seo";
import Header from "../../components/Header";
import { graphql } from 'gatsby';
import ProjectContainer from "../../components/ProjectContainer";

import * as styles from "../../styles/projects.module.css"

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
 

function Projects({ data }) {

  const revealRefs = useRef([]);
  revealRefs.current = [];

  const sectionRef = useRef();

  const projects = data.projects.nodes

  useEffect(() => {

    gsap.from(sectionRef.current, {duration: 1, backgroundColor: "white"})

    gsap.from(revealRefs.current, {
      duration: 1, 
      autoAlpha: 0,
      stagger: 0.2,
      ease: 'none',
      delay: 1,
    })

  }, [])

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }
  
  return (
    <section className={styles.projects} id="projects" ref={sectionRef}>
      <Header />
      <Seo title="Projects" />

      <div className={styles.projectsContainer}>

          { projects.map(project => 
            <div key={project.frontmatter.title} ref={addToRefs}>
              <ProjectContainer 
                  project={project.frontmatter}
              />
            </div>
          )}

      </div>

    </section>
  )
}

export default Projects

export const query = graphql`
query Projects {
  projects: allMarkdownRemark(sort: {order: ASC, fields: frontmatter___order}) {
    nodes {
      frontmatter {
        slug
        stack
        title
        image1 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: AUTO)
          }
        }
        type
      }
      id
    }
  }
}
`

import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'


import * as styles from '../styles/ProjectContainer.module.css'

function ProjectContainer({ project }) {

    const {
        slug,
        stack,
        title,
        image1,
        type
    } = project

    const projectImage = getImage(image1)

    const stackItems = stack.split(',')

   

    return (
        
        <Link to={ `/projects/${slug}` }>
            <div className={styles.container}>

                    <div className={styles.header}>
                        <div>
                            <h2 
                                className={styles.containerHeader}
                            >
                                {title}
                            </h2>
                            <p>{type}</p>
                        </div>
                        <ul className={styles.list}>
                            {stackItems.map(item => (
                                <li key={item}>- {item}</li>
                            ))}
                        </ul>

                    </div>

                    <div>

                        <GatsbyImage 
                            image={projectImage}
                            alt={title}
                            loading="lazy"
                        />

                    </div>

            </div>

        </Link>
    )
}

export default ProjectContainer
